import '../../css/index.css'
import 'react-aspect-ratio/aspect-ratio.css'

import React, { Fragment, useEffect, useState } from 'react'
import {
  compact,
  groupBy,
  isEmpty,
  isNil,
  keys,
  map,
  omit,
  range,
  reduce,
  find,
  padStart,
} from 'lodash'
import { Helmet } from 'react-helmet'
import { DateTime } from 'luxon'
import AlgoliaPlaces from 'algolia-places-react'
import { v4 as uuid } from 'uuid'
import AspectRatio from 'react-aspect-ratio'

import { useReportsBacklogApi } from '@ephemeris/fulfillment-api/src/useReportsBacklogApi'

import { getNameParticles, getFullName } from '@ephemeris/utils/src/birth-info'
import { getReportName } from '@ephemeris/utils/src/report'
import { getDateTime, getGmtOffset } from '@ephemeris/utils/src/DateTime'
import { isValidEmail } from '@ephemeris/utils/src/string'
import { createGroupId } from '@ephemeris/fulfillment/src/reports-backlog'
import Loader from '@ephemeris/react-components/src/Loader'
import BorderTopLeft from '@ephemeris/assets/src/images/svg/cover-borders/top-left.svg'
import BorderTopRight from '@ephemeris/assets/src/images/svg/cover-borders/top-right.svg'
import BorderBottomLeft from '@ephemeris/assets/src/images/svg/cover-borders/bottom-left.svg'
import BorderBottomRight from '@ephemeris/assets/src/images/svg/cover-borders/bottom-right.svg'

import type { FC, ChangeEvent } from 'react'
import type { PageProps } from 'gatsby'

const { getReportsBacklogItemsInOrder, updateReportsBacklogItems } =
  useReportsBacklogApi()

interface Person {
  id: string
  birthInfo: BirthInfo
  currentLocation: { name: string; latitude: number; longitude: number }
  destinationEmail: string
}

interface ReportPreviewProps {
  backlogItem: ReportsBacklog.Item
  person?: Optional<Person>
}

interface PlacesChangeEvent {
  suggestion: {
    value: string
    latlng: {
      lat: number
      lng: number
    }
  }
}

interface PlacesResult {
  name: string
  latitude: number
  longitude: number
}

interface PlacesSelectEvent {
  target: {
    name: string
    value: Optional<PlacesResult>
  }
}

interface GmtOffsetSelectEvent {
  target: {
    name: string
    value: number
  }
}

const EditIcon: FC = () => (
  <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 383.947 383.947'>
    <g>
      <g>
        <g>
          <polygon points='0,303.947 0,383.947 80,383.947 316.053,147.893 236.053,67.893' />
          <path
            fill='#161c29'
            d='M377.707,56.053L327.893,6.24c-8.32-8.32-21.867-8.32-30.187,0l-39.04,39.04l80,80l39.04-39.04
     C386.027,77.92,386.027,64.373,377.707,56.053z'
          />
        </g>
      </g>
    </g>
  </svg>
)

const LocationInput: FC<{
  name: string
  placeholder: string
  onSelect: (result: Optional<PlacesResult>) => void
  isValid: boolean
  defaultValue?: string
}> = ({ name, placeholder, onSelect: handleSelect, isValid, defaultValue }) => {
  const [resultChangeCount, setResultChangeCount] = useState(0)
  const [result, setResult] = useState<Optional<PlacesResult>>()

  useEffect(() => {
    if (resultChangeCount === 0) {
      setResultChangeCount(resultChangeCount + 1)
      return
    }

    handleSelect(result)
  }, [result])

  return (
    <AlgoliaPlaces
      className={`
        w-full rounded-md border h-11 px-2
        ${!isValid ? 'border-rose-400' : 'border-gray-200'}
      `}
      name={name}
      options={{ type: 'city' }}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={({ suggestion }: PlacesChangeEvent): void => {
        const {
          value: name,
          latlng: { lat: latitude, lng: longitude },
        } = suggestion

        setResult({ name, latitude, longitude })
      }}
      onClear={setResult}
    />
  )
}

function canGetGmtOffset(birthInfo: FullyPartial<BirthInfo>): boolean {
  const { location, date } = birthInfo
  const { year, month, day } = date ?? {}
  const { latitude, longitude } = location ?? {}

  return (
    !isNil(year) &&
    !isNaN(year) &&
    !isNil(month) &&
    !isNaN(month) &&
    !isNil(day) &&
    !isNaN(day) &&
    !isNil(latitude) &&
    !isNil(latitude) &&
    !isNaN(longitude as number) &&
    !isNaN(longitude as number)
  )
}

function isCompleteBirthInfo(birthInfo: FullyPartial<BirthInfo>): boolean {
  const { firstName, lastName, location, date } = birthInfo
  const { hour, minute, gmtOffset } = date ?? {}
  const { name: locationName } = location ?? {}

  return (
    canGetGmtOffset(birthInfo) &&
    !isNil(firstName) &&
    !isEmpty(firstName) &&
    !isNil(hour) &&
    !isNaN(hour) &&
    !isNil(minute) &&
    !isNaN(minute) &&
    !isNil(gmtOffset) &&
    !isNaN(gmtOffset) &&
    !isNil(locationName) &&
    !isEmpty(locationName)
  )
}

function getPeopleFromBacklogItems(
  backlogItems: ReportsBacklog.Item[]
): Partial<Person>[] {
  const groupedBacklogItems = groupBy(backlogItems, 'nameOnReport')

  return compact(
    map(groupedBacklogItems, (backlogItems): Optional<Partial<Person>> => {
      const [{ birthInfo, destinationEmail, currentLocation }] = backlogItems

      if (!isCompleteBirthInfo(birthInfo)) {
        return
      }

      const id = uuid()

      return { id, birthInfo, destinationEmail, currentLocation }
    })
  )
}

const ReportPreview: FC<ReportPreviewProps> = ({ backlogItem, person }) => {
  const DEFAULT_BIRTH_INFO: BirthInfo = {
    date: {
      year: 1992,
      month: 6,
      day: 12,
      hour: 13,
      minute: 40,
      gmtOffset: -3,
    },
    firstName: 'Daniela',
    lastName: 'Vianna',
    location: {
      name: 'Berkeley, California, United States of America',
      latitude: 1,
      longitude: 1,
    },
  }
  const hasAssignedPerson = !isNil(person)
  const birthInfo = hasAssignedPerson ? person!.birthInfo : DEFAULT_BIRTH_INFO
  const birthDate = getDateTime(birthInfo)
  const { reportType } = backlogItem
  const {
    firstName,
    lastName,
    location: { name: locationName },
  } = birthInfo

  const reportName = getReportName(reportType)
  const personNameText = hasAssignedPerson
    ? `${firstName}${!isEmpty(lastName) ? ` ${lastName}` : ''}`
    : 'Select Person'
  const birthLocationText = hasAssignedPerson ? locationName : 'place of birth'
  const birthDateText = hasAssignedPerson
    ? birthDate.toLocaleString(DateTime.DATE_MED)
    : 'mm/dd/yyyy'
  const birthTimeText = hasAssignedPerson
    ? `${birthDate.toFormat(`hh:mm a`)} ${birthDate.offsetNameLong}`
    : 'hh:mm time zone'

  return (
    <>
      <AspectRatio ratio={623 / 793} className={`w-full`}>
        <div
          className={`
            flex flex-col justify-center items-center
            bg-white relative w-full h-full p-4 font- exo2
            border-2 border-gray-200 rounded-[0.25rem] overflow-hidden
            ${!hasAssignedPerson ? '' : 'shadow-lg'}
          `}
        >
          <div
            className={`
              flex flex-col justify-center items-center
              p-6 rounded-sm
            `}
          >
            <span className='absolute text-xs font-bold top-4 font-copperplate'>
              Ephemeris Co.
            </span>
            <img
              src={BorderTopLeft}
              className='absolute top-8 left-8 w-[15%]'
            />
            <img
              src={BorderTopRight}
              className='absolute top-8 right-8 w-[10%]'
            />
            <img
              src={BorderBottomLeft}
              className='absolute bottom-8 left-8 w-[15%]'
            />
            <img
              src={BorderBottomRight}
              className='absolute bottom-8 right-8 w-[6.5%]'
            />
            <div className='text-center'>
              <p className='text-xs font-medium'>{reportName} for</p>
              <div className='mt-2'>
                <div
                  className={`
                    text-lg font-bold uppercase font-copperplate
                  `}
                >
                  {personNameText}
                </div>

                <div className='h-1 mt-2 mb-4 bg-ephemerisBlue' />

                <div className='text-xs font-light'>
                  <p>{birthLocationText}</p>
                  <p>{birthDateText}</p>
                  <p>{birthTimeText}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AspectRatio>
      {hasAssignedPerson && (
        <div className='mt-2 text-xs text-center'>
          <span className=''>Will be sent to:</span>{' '}
          <span className='font-mono font-bold'>
            {person?.destinationEmail}
          </span>
        </div>
      )}
    </>
  )
}

const PersonCreator: FC<{
  person?: Optional<Person>
  onBack: () => void
  onCreate: (person: Person) => void
}> = ({ onBack, onCreate, person: existingPerson }) => {
  const [person, setPerson] =
    useState<Optional<FullyPartial<Person>>>(existingPerson)
  const [shouldCheckIfCanGetGmtOffset, setShouldCheckIfCanGetGmtOffset] =
    useState(false)

  useEffect(() => {
    if (!shouldCheckIfCanGetGmtOffset) {
      setShouldCheckIfCanGetGmtOffset(true)
      return
    }

    const birthInfo = person?.birthInfo ?? {}

    if (!canGetGmtOffset(birthInfo)) {
      return
    }

    getGmtOffset(birthInfo as BirthInfo).then(gmtOffset => {
      console.log(`--- did get gmt offset:`, gmtOffset)
      setGmtOffset(gmtOffset)
    })
  }, [person?.birthInfo?.date, person?.birthInfo?.location])

  const [gmtOffset, setGmtOffset] = useState<Optional<number>>()
  useEffect(() => {
    onValueUpdate({ target: { name: 'gmtOffset', value: gmtOffset! } })
  }, [gmtOffset])

  const [initialPerson, setInitialPerson] = useState<Optional<Person>>()
  const [isEditing, setIsEditing] = useState<Optional<boolean>>()
  useEffect(() => {
    if (!isNil(isEditing)) {
      return
    }

    const editing = !isNil(person)

    if (editing) {
      setInitialPerson(person as Person)
    }

    setIsEditing(editing)
  }, [])

  function onValueUpdate(
    event:
      | ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | PlacesSelectEvent
      | GmtOffsetSelectEvent
  ): void {
    const { target } = event
    const { name } = target

    const { birthInfo } = person ?? {}

    if (name === 'name') {
      const name = target.value as string

      setPerson({
        ...person,
        birthInfo: { ...birthInfo, ...getNameParticles(name) },
      })
    } else if (name === 'birthDate') {
      const isoBirthDate = target.value as string
      const [year, month, day] = isoBirthDate
        .split('-')
        .map(value => Number(value))

      setPerson({
        ...person,
        birthInfo: {
          ...birthInfo,
          date: { ...birthInfo?.date, year, month, day },
        },
      })
    } else if (name === 'birthHour') {
      const hour = Number(target.value)

      setPerson({
        ...person,
        birthInfo: {
          ...birthInfo,
          date: { ...birthInfo?.date, hour },
        },
      })
    } else if (name === 'birthMinute') {
      const minute = Number(target.value)

      setPerson({
        ...person,
        birthInfo: {
          ...birthInfo,
          date: { ...birthInfo?.date, minute },
        },
      })
    } else if (name === 'birthLocation') {
      const location = (target.value as Partial<PlacesResult> | undefined) ?? {
        name: '',
      }

      setPerson({
        ...person,
        birthInfo: {
          ...birthInfo,
          location,
        },
      })
    } else if (name === 'currentLocation') {
      const location = (target.value as Partial<PlacesResult> | undefined) ?? {
        latitude: 0,
        longitude: 0,
      }

      setPerson({
        ...person,
        currentLocation: location,
      })
    } else if (name === 'destinationEmail') {
      const destinationEmail = target.value as string

      setPerson({
        ...person,
        destinationEmail,
      })
    } else if (name === 'gmtOffset') {
      const gmtOffset = target.value as number

      setPerson({
        ...person,
        birthInfo: { ...birthInfo, date: { ...birthInfo?.date, gmtOffset } },
      })
    }
  }

  const { firstName = '', lastName } = person?.birthInfo ?? {}
  const fullName = getFullName({ firstName, lastName })
  const isValidNameField = isNil(person?.birthInfo?.firstName)
    ? true
    : !isEmpty(person?.birthInfo?.firstName)
  const isValidEmailField = isNil(person?.destinationEmail)
    ? true
    : isValidEmail(person?.destinationEmail ?? '')
  const isValidHourSelect = isNil(person?.birthInfo?.date?.hour)
    ? true
    : person!.birthInfo!.date!.hour >= 0
  const isValidMinuteSelect = isNil(person?.birthInfo?.date?.minute)
    ? true
    : person!.birthInfo!.date!.minute >= 0
  const isValidBirthLocation = isNil(person?.birthInfo?.location)
    ? true
    : !isEmpty(person?.birthInfo?.location.name)
  const isValidCurrentLocation = isNil(person?.currentLocation)
    ? true
    : person?.currentLocation?.latitude !== 0 &&
      person?.currentLocation?.longitude !== 0

  const canCreatePerson =
    !isNil(person) &&
    !isNil(person.birthInfo) &&
    isCompleteBirthInfo(person.birthInfo) &&
    !isNil(person.destinationEmail) &&
    !isEmpty(person.destinationEmail) &&
    isValidNameField &&
    isValidEmailField &&
    isValidHourSelect &&
    isValidMinuteSelect &&
    isValidBirthLocation &&
    isValidCurrentLocation

  if (!canCreatePerson) {
    if (!isNil(person)) {
      console.log(`--- birthInfo:`, person.birthInfo)
    }
  }

  return (
    <div
      className={`
        fixed top-0 left-0 w-screen h-screen overflow-scroll bg-gray-50 z-10 p-6 font-exo2 text-ephemerisBlue
        flex justify-center
      `}
    >
      <div className='w-full max-w-[600px]'>
        <button className='py-2 hover:underline' onClick={onBack}>
          ← Back
        </button>

        <div className='mt-4' />
        <h1 className='text-xl font-bold'>
          {isEmpty(fullName) ? (
            isEditing ? (
              <span className='italic text-gray-300'>
                {getFullName(initialPerson!.birthInfo)}
              </span>
            ) : (
              'New Person'
            )
          ) : (
            fullName
          )}
        </h1>
        <div className='mt-4' />
        <form
          className='text-ephemerisBlue'
          onSubmit={(event): void => {
            event.preventDefault()
          }}
        >
          <div className='flex flex-col'>
            <label
              className={`
            text-xs font-semibold ml-2
            ${!isValidNameField ? 'text-rose-400' : ''}
          `}
            >
              Name on Report
            </label>
            <input
              name='name'
              type='text'
              className={`
              mt-1 w-full rounded-md border h-11 px-2 outline-none
              ${!isValidNameField ? 'border-rose-400' : 'border-gray-200'}
            `}
              placeholder='ex.: Anne Shirley'
              onChange={onValueUpdate}
              defaultValue={fullName}
            />
            {!isValidNameField && (
              <p className='text-xs text-rose-400'>
                * This field cannot be empty
              </p>
            )}
          </div>
          <div className='mt-2' />
          <div className='flex flex-col'>
            <label className='ml-2 text-xs font-semibold'>Birth Date</label>
            <input
              name='birthDate'
              type='date'
              className='w-full px-2 mt-1 border border-gray-200 rounded-md h-11'
              placeholder='Pick a date'
              onChange={onValueUpdate}
              defaultValue={
                person?.birthInfo?.date &&
                `${person!.birthInfo!.date.year}-${padStart(
                  `${person!.birthInfo!.date.month}`,
                  2,
                  '0'
                )}-${padStart(`${person!.birthInfo!.date.day}`, 2, '0')}`
              }
            />
          </div>
          <div className='mt-4' />
          <div className='flex flex-col'>
            <label
              className={`
            text-xs font-semibold ml-2
            ${
              !(isValidHourSelect && isValidMinuteSelect) ? 'text-rose-400' : ''
            }
          `}
            >
              Birth Time
            </label>
            <div className='flex mt-1'>
              <div className='flex flex-col'>
                <select
                  name='birthHour'
                  className={`
                  w-full p-2 rounded-md border text-sm outline-none
                  ${!isValidHourSelect ? 'border-rose-400' : 'border-gray-200'}
                `}
                  onChange={onValueUpdate}
                  value={person?.birthInfo?.date?.hour}
                >
                  <option value={-1}>Hour</option>
                  {map(range(0, 24), hour => {
                    const isPm = hour >= 12
                    const twelveHoursFormatValue = isPm
                      ? hour > 12
                        ? hour - 12
                        : hour
                      : hour
                    const amPmSymbol = isPm ? 'PM' : 'AM'
                    return (
                      <option value={hour} key={hour}>
                        {`${padStart(
                          `${twelveHoursFormatValue}`,
                          2,
                          '0'
                        )} ${amPmSymbol}`}
                      </option>
                    )
                  })}
                </select>
                {!isValidHourSelect && (
                  <p className='text-xs text-rose-400'>* Select an hour</p>
                )}
              </div>
              <span className='ml-2 text-lg'>:</span>
              <div className='flex flex-col ml-2'>
                <select
                  name='birthMinute'
                  className={`
                  w-full p-2 rounded-md border text-sm outline-none
                  ${
                    !isValidMinuteSelect ? 'border-rose-400' : 'border-gray-200'
                  }
                `}
                  onChange={onValueUpdate}
                  value={person?.birthInfo?.date?.minute}
                >
                  <option value={-1}>Minute</option>
                  {map(range(0, 60), minute => {
                    return (
                      <option value={minute} key={minute}>
                        {padStart(`${minute}`, 2, '0')}
                      </option>
                    )
                  })}
                </select>
                {!isValidMinuteSelect && (
                  <p className='text-xs text-rose-400'>* Select a minute</p>
                )}
              </div>
            </div>
          </div>
          <div className='mt-4' />
          <div className='flex flex-col'>
            <label
              className={`
              text-xs font-semibold ml-2
              ${!isValidBirthLocation ? 'text-rose-400' : ''}
            `}
            >
              Place of Birth
            </label>
            <div className='mt-1' />
            <LocationInput
              name='birthLocation'
              placeholder='ex.: Berkeley, California, United States'
              isValid={isValidBirthLocation}
              onSelect={(result): void => {
                onValueUpdate({
                  target: { name: 'birthLocation', value: result },
                })
              }}
              defaultValue={person?.birthInfo?.location?.name}
            />
            {!isValidBirthLocation && (
              <p className='text-xs text-rose-400'>
                * This field cannot be empty
              </p>
            )}
          </div>
          <div className='mt-4' />
          <div className='flex flex-col'>
            <label className='ml-2 text-xs font-semibold'>
              Current Place of Residence
            </label>
            <div className='mt-1' />
            <LocationInput
              name='currentLocation'
              placeholder='ex.: Los Gatos, California, United States'
              isValid={isValidCurrentLocation}
              defaultValue={person?.currentLocation?.name}
              onSelect={(result): void => {
                onValueUpdate({
                  target: { name: 'currentLocation', value: result },
                })
              }}
            />
            {!isValidCurrentLocation && (
              <p className='text-xs text-rose-400'>
                * This field cannot be empty
              </p>
            )}
          </div>
          <div className='mt-4' />
          <div className='flex flex-col'>
            <label
              className={`
            text-xs font-semibold ml-2
            ${!isValidEmailField ? 'text-rose-400' : ''}
          `}
            >
              Email to Send Report
            </label>
            <input
              name='destinationEmail'
              type='email'
              className={`
              mt-1 w-full rounded-md border h-11 px-2 outline-none
              ${!isValidEmailField ? 'border-rose-400' : 'border-gray-200'}
            `}
              placeholder='ex.: starchild@ephemeris.co'
              onChange={onValueUpdate}
              defaultValue={person?.destinationEmail}
            />
            {!isValidEmailField && (
              <p className='text-xs text-rose-400'>
                *{' '}
                {isEmpty(person?.destinationEmail)
                  ? 'This fields cannot be empty'
                  : 'Invalid email address'}
              </p>
            )}
          </div>
          <div className='mt-6' />
          <input
            type='submit'
            disabled={!canCreatePerson}
            className={`
            w-full p-3 bg-primary rounded-md cursor-pointer hover:bg-opacity-50 outline-none transition-all
            ${canCreatePerson ? 'text-gray-50' : 'bg-opacity-20 text-white'}
          `}
            value={`${isEditing ? 'Save' : 'Create Person'}`}
            onClick={(): void => {
              const { id = uuid() } = person!
              const newPerson = { ...person, id } as Person
              onCreate(newPerson)
              setPerson(undefined)
            }}
          />
        </form>
        <button
          className='w-full p-3 text-sm rounded-md text-ephemerisBlue 50 hover:underline'
          onClick={onBack}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export const CustomizeReports: FC<PageProps> = ({ params }) => {
  const { orderId } = params as { orderId: string }
  const [backlogItems, setBacklogItems] =
    useState<Optional<ReportsBacklog.Item[]>>(undefined)
  const [people, setPeople] = useState<Partial<Person>[]>([])
  const [backlogItemToPersonMap, setBacklogItemToPersonMap] = useState<
    Record<string, Person>
  >({})

  async function customizeReports(): Promise<void> {
    const updatedBacklogItems = map(
      backlogItemToPersonMap,
      (person, backlogItemId): ReportsBacklog.Item => {
        const backlogItem = backlogItems!.find(
          ({ id }) => id === backlogItemId
        )!
        const { orderId } = backlogItem
        const { destinationEmail, birthInfo, currentLocation } = person
        const groupId = createGroupId(orderId, destinationEmail)
        const nameOnReport = getFullName(birthInfo)

        return {
          ...backlogItem,
          birthInfo,
          currentLocation,
          groupId,
          nameOnReport,
          destinationEmail,
          status: 'ReadyToBeGenerated',
        }
      }
    )

    try {
      await updateReportsBacklogItems(updatedBacklogItems)
      setBacklogItems([])
    } catch (error) {
      alert(
        'An error occurred. Please, try again later. If the error persists, contact us at help@ephemeris.co'
      )
    }
  }

  function getBacklogItemsToWhichPersonIsAssigned(
    person: Person
  ): ReportsBacklog.Item[] {
    return compact(
      map(backlogItemToPersonMap, ({ id }, backlogItemId) => {
        if (id !== person.id) {
          return
        }
        return find(backlogItems, ({ id }) => id === backlogItemId)
      })
    )
  }

  function getPersonAssignedToBacklogItem(
    backlogItem: ReportsBacklog.Item
  ): Optional<Person> {
    return backlogItemToPersonMap[backlogItem.id]
  }

  function revokePersonFromBacklogItems(
    backlogItems: ReportsBacklog.Item[]
  ): void {
    const backlogItemsIds = map(backlogItems, ({ id }) => id)
    const newMap = omit(backlogItemToPersonMap, backlogItemsIds)

    setBacklogItemToPersonMap(newMap)
  }

  function assignPersonToBacklogItems(
    person: Optional<Person>,
    backlogItems: ReportsBacklog.Item[]
  ): void {
    if (isNil(person)) {
      revokePersonFromBacklogItems(backlogItems)
      return
    }

    const updatedBacklogItemToPersonMap = reduce(
      backlogItems,
      (acc, backlogItem) => ({ ...acc, [backlogItem.id]: person }),
      {} as Dictionary<Person>
    )

    setBacklogItemToPersonMap({
      ...backlogItemToPersonMap,
      ...updatedBacklogItemToPersonMap,
    })

    console.log(
      `--- did assign backlogItems (${backlogItems
        .map(b => b.id)
        .join(', ')}) to person:`,
      person.birthInfo
    )
  }

  function findPersonById(id: string): Optional<Partial<Person>> {
    return find(people, person => {
      return person.id === id
    })
  }

  async function createPerson(
    person: Person,
    backlogItem: ReportsBacklog.Item
  ): Promise<void> {
    const peopleOtherThanThis = people.filter(({ id }) => id !== person.id)
    setPeople([...peopleOtherThanThis, person])

    const backlogItems = getBacklogItemsToWhichPersonIsAssigned(person)

    assignPersonToBacklogItems(person, [...backlogItems, backlogItem])
  }

  const PeoplePicker: FC<{
    selectedPerson: Optional<Person>
    onSelectPerson: (person: Person) => void
    onCreatePerson: () => void
  }> = ({
    onSelectPerson: handleSelectPerson,
    onCreatePerson: handleCreatePerson,
    selectedPerson,
  }) => {
    const value = isNil(selectedPerson) ? 'Select person' : selectedPerson.id

    return (
      <select
        className='h-8 pl-2 border-2 border-gray-200 rounded-md'
        value={value}
        onChange={(event): void => {
          const { target } = event
          const { value } = target

          if (value === 'create') {
            handleCreatePerson()
          } else {
            const personId = target.value
            const person = findPersonById(personId)!

            handleSelectPerson(person as Person)
          }
        }}
      >
        <option>Select person</option>
        {map(people, person => {
          const nameOnReport = getFullName(person.birthInfo!)

          return (
            <option value={person.id} key={person.id}>
              {nameOnReport}
            </option>
          )
        })}
        <option value='create'>✚ Create new person...</option>
      </select>
    )
  }

  const CustomizableItem: FC<{
    backlogItem: ReportsBacklog.Item
    person: Optional<Person>
    index: number
  }> = ({ backlogItem, person, index }) => {
    const [isCreating, setIsCreating] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
      document
        .querySelector('body')
        ?.setAttribute(
          'style',
          `${isCreating || isEditing ? 'margin: 0; overflow: hidden;' : ''}`
        )
    }, [isCreating, isEditing])

    return (
      <Fragment key={backlogItem.id}>
        {(isCreating || isEditing) && (
          <PersonCreator
            person={isEditing ? person : undefined}
            onCreate={(person): void => {
              createPerson(person, backlogItem)
              setIsCreating(false)
              setIsEditing(false)
            }}
            onBack={(): void => {
              setIsCreating(false)
              setIsEditing(false)
            }}
          />
        )}
        <div
          className={`
          bg-coolGray-200 px-8 py-4 w-full
          ${index % 2 === 0 ? 'bg-opacity-50' : ''}
        `}
        >
          <div className='flex justify-center'>
            <div
              className={`
              w-full min-w-[85vw] max-w-[450px]
              md:min-w-[0vw]
            `}
            >
              <div className='relative'>
                <span className='text-lg font-bold'>Whose report is this?</span>
                <div className='flex items-center'>
                  <PeoplePicker
                    selectedPerson={person}
                    onSelectPerson={(person): void => {
                      assignPersonToBacklogItems(person, [backlogItem])
                    }}
                    onCreatePerson={(): void => {
                      setIsCreating(true)
                    }}
                  />
                  {person && (
                    <button
                      className='flex items-center justify-between h-8 px-2 py-1 ml-2 bg-white border-2 border-gray-200 rounded'
                      onClick={(): void => {
                        setIsEditing(true)
                      }}
                    >
                      <div className='w-3.5'>
                        <EditIcon />
                      </div>
                      <span className='ml-2 text-xs'>Edit</span>
                    </button>
                  )}
                </div>
              </div>
              <div className='mt-2' />
              <ReportPreview backlogItem={backlogItem} person={person} />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  useEffect(() => {
    getReportsBacklogItemsInOrder(Number(orderId)).then(backlogItems => {
      const backlogItemsToBeEdited = backlogItems.filter(
        backlogItem => backlogItem.status === 'WaitingForMissingData'
      )
      const people = getPeopleFromBacklogItems(backlogItems)
      setPeople(people)

      setBacklogItems(backlogItemsToBeEdited)
    })
  }, [])

  const totalReportsCount = (backlogItems ?? []).length
  const customizedReportsCount = keys(backlogItemToPersonMap).length
  const haveAllReportsBeenCustomized =
    customizedReportsCount === totalReportsCount

  return (
    <>
      <Helmet>
        <title>Ephemeris Reports - Customize Reports</title>
      </Helmet>
      <main
        className={`
        w-screen bg-gray-50 text-ephemerisBlue font-exo2 flex flex-col
        ${isNil(backlogItems) || isEmpty(backlogItems) ? 'h-screen' : ''}
      `}
      >
        <div className='py-6'>
          <p className='text-2xl text-center font-copperplate'>Ephemeris Co.</p>
        </div>

        <div
          className={`flex flex-col pb-12 flex-grow justify-center items-center`}
        >
          {isNil(backlogItems) ? (
            <div className='flex flex-col items-center justify-center'>
              <div className='flex justify-center'>
                <div className='px-8 max-w-[450px] items-center flex'>
                  <Loader style='dark' />{' '}
                  <span className='ml-2'>Loading reports</span>
                </div>
              </div>
            </div>
          ) : isEmpty(backlogItems) ? (
            <div className='flex flex-col justify-center'>
              <div className='flex justify-center'>
                <div className='px-8 text-center max-w-[450px]'>
                  <p>
                    No more reports to be customized. You will receive the
                    reports in your email soon.
                  </p>
                  <p className='mt-4 underline'>
                    <a href='https://ephemeris.co/account'>See order details</a>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            map(backlogItems, (backlogItem, index) => {
              const person = getPersonAssignedToBacklogItem(backlogItem)

              return (
                <CustomizableItem
                  backlogItem={backlogItem}
                  person={person}
                  index={index}
                  key={`customizable-item-${index}`}
                />
              )
            })
          )}
          {!isNil(backlogItems) && !isEmpty(backlogItems) && (
            <div className='px-8 mt-4'>
              <div className='mt-1' />
              <p
                className={`
            text-xs text-center
            ${!haveAllReportsBeenCustomized ? 'font-light' : ''}
          `}
              >
                <span>{`${
                  haveAllReportsBeenCustomized
                    ? 'All done!'
                    : `${customizedReportsCount} of ${totalReportsCount} reports customized`
                }`}</span>
              </p>
              <div className='mt-1' />
              <button
                disabled={!haveAllReportsBeenCustomized}
                className={`
                w-full p-4 rounded-md
                ${
                  haveAllReportsBeenCustomized
                    ? 'bg-primary text-gray-50 shadow-md'
                    : 'bg-gray-200 text-white cursor-default'
                }
            `}
                onClick={customizeReports}
              >
                Save Customizations
              </button>
            </div>
          )}
        </div>
      </main>
    </>
  )
}
